/* globals jQuery */
if (window.NodeList && !NodeList.prototype.forEach) {NodeList.prototype.forEach = Array.prototype.forEach;}

(function($){
    
  // Adds a class to navbar when user is no longer at the top of the page for targeting style changes for fixed navbars
    var c, currentScrollTop = 0;
    function nav() {
      var $navbar = $('#navbar'),
          pos     = (document.documentElement.scrollTop||document.body.scrollTop);
          
      currentScrollTop = pos;
      if (!$navbar.hasClass('static')){
        if (c < currentScrollTop && pos > 100) $navbar.addClass("scrollUp");
        else if (c > currentScrollTop && !(pos <= 100)) $navbar.removeClass("scrollUp");
        
        if (pos > 10) $navbar.addClass("scrolled");
        else $navbar.removeClass("scrolled");
        
        c = currentScrollTop;
      }
      
      return false;
    }
    
  // Menu Tray
    function menutray() {
      var body = document.querySelector('body'), menu = document.getElementById('menutray');
      
      if ( menu ) {
        var openBtns  = document.querySelectorAll('.more'),
            closeBtn  = menu.querySelector('.closebtn'),
            activeBtn = false;
            
        menu.style.display = '';
            
        closeBtn.addEventListener('click', function(e) {
          e.preventDefault();
          menu.classList.remove('open');
          removeClickListener();
          return false;
        });
        
        const outsideClickListener = function( event ) {
          if ( !menu.contains(event.target) && !activeBtn.contains(event.target) ) {
            event.preventDefault();
            removeClickListener();
          }
        },
        removeClickListener = function() {
          body.classList.remove('scroll-lock');
          menu.classList.remove('open');
          document.removeEventListener('click', outsideClickListener, {capture:true})
        }
        
        openBtns.forEach(function( btn ) {
          btn.addEventListener('click', function( event ) {
            event.preventDefault();
            activeBtn = btn;
            
            if ( event.target == btn && menu.classList.contains('open') ) {
              menu.classList.remove('open');
              removeClickListener();
            }
            else {
              body.classList.add('scroll-lock');
              menu.classList.add('open');
              document.addEventListener('click', outsideClickListener, {capture:true});
            }
            
            return false;
          });
        });
      }
    }
    
  // Visibility observer for animation triggering
    function observerInit(){
      var targets = [].slice.call(document.querySelectorAll('.content > *')),
          config = {
            rootMargin: '0px',
            threshold: 0.1
          };
          
      if ( !('IntersectionObserver' in window) ) {
        targets.forEach(function(target) {
          if ( target.tagName !== 'FIGURE' ) target.classList.add('visible');
        });
      } else {
        let observer = new IntersectionObserver(onIntersection, config);
        targets.forEach(target => {
          observer.observe(target);
        });
      }
    }
    function onIntersection(entries) {
      entries.forEach(entry => {
        if ( entry.target.tagName !== 'FIGURE' ) {
          if ( entry.isIntersecting ) entry.target.classList.add('visible');
          else entry.target.classList.remove('visible');
        }
      });
    }
    
  // Cover Video Autoplay for Tablets and Mobile
    function coverVideo() {
      var video = document.querySelector('video[autoplay]');
      if ( video ) {
        video.setAttribute('playsinline', true);
        if ( video.paused ) video.play();
      }
    }
    
  $(function(){
    coverVideo();
    menutray();
    nav();
    observerInit();
    
    $(window).scroll(function() { nav(); });
  })
})(jQuery);
